import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from 'react-bootstrap/Container'
import Row  from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Twilight from "../components/Twilight"
import HalfDay from "../components/HalfDay"
import FullDay from "../components/FullDay"
import FullDayTeal from "../components/FullDayTeal"
import HalfDayTeal from "../components/HalfDayTeal"
import Care from "../components/Care"

const PackagesPage = () => (
  <Layout>
    <SEO title="Packages and Costs" />
    <Container className="my-4">
      <h1 className="text-primary">Packages and Costs</h1>
      <p className="lead mb-4 mb-lg-0 text-primary">
        <em>
          “Our success cannot be measured within the individual services we provide. Instead, our success grows from
          within the RELATIONSHIPS we form.”
        </em>
      </p>
      <Row className="align-items-center">
        <Col xl={{span: 5, offset: 0, order:'last'}} lg={{span: 6, offset: 0, order:'last'}} xs={{span: 8, offset: 2}} className="pl-lg-5">
          <Care/>
        </Col>
        <Col xl={7} lg={6}>
          <h3 className="text-secondary">Our Approach</h3>
          <p>
            We know that our relationships with our schools, and the families and communities they serve, are the key to effecting positive outcomes for children and young people. This is why the foundation of our support is built upon collaborative working, which facilitates the 'assess, plan, do, review process', and is underpinned by values that drive a caring and friendly service.
          </p>
          <p>
            An added bonus for our schools is that we are family run, ensuring that communication, vision, service-planning and organisation are all joined up. Our schools can also be guaranteed continuity of service via the same psychologist, with no changes or need to re-establish relationships and ways of working. Options are also available for collaborative working between psychologists, which enables our schools to draw upon our collective, broader range of expertise and skill set within applied psychology.
          </p>
          <p>
            In essence, our schools become part of our team, and through our <span className="text-primary">RELATIONSHIPS</span> we hope our schools let us become part of theirs too.
          </p>
        </Col>
      </Row>
      <h3 className="text-primary">Ongoing Support Packages</h3>
      <p>
        Due to our approach, many of the schools we work with want to secure our services and, therefore, we offer ongoing annual packages of support. This works great for us too, as we can ensure our support is well-organised to meet our schools' needs (often sooner), and enables more effective and creative applications of psychology.
      </p>
      <p>
        For our annual packages, we work flexibly to identify the level of need and type of support required (either individually or collectively for children or young people, staff training, consultancy or project work). Packages are based on the total number of days of support required, and range from a minimum annual package of 3 days support (a day a term) to a maximum annual package of 39 days support (a day a week). Typically, we attend schools for full days and occasionally for half days, where this is needed. We have found that a full day ensures our schools have the maximum amount of time from us and enables utilising lunch time as, like many of us working in education, we don't mind a working lunch!
      </p>
      <p>
        Our costings are set against an ethical pricing policy, that considers the typical daily rates to schools found in local authority educational psychology traded services, or other independent educational psychology services. At present, they are set at £630 plus VAT per day, or £335 plus VAT per half day. Costings are also more favourable for schools as a package of planned support.
      </p>
      <p>
        Individual pricing for completed work will, however, vary depending upon the duration and complexity of support required and, therefore, needs to be agreed on an individual basis, and will be discussed as we plan work together.
      </p>
      <Row xs={1} md={4} lg={5} className="justify-content-center mt-4">
        <Col className="text-center">
          <HalfDayTeal/>
          <h4 className="text-primary mt-2">Half Day Session</h4>
          <p className="mb-0">3 Hours</p>
          <p className="mb-4">£335 plus VAT</p>
        </Col>
        <Col className="text-center">
          <FullDayTeal/>
          <h4 className="text-primary mt-2">Full Day Session</h4>
          <p className="mb-0">6 Hours</p>
          <p className="mb-4">£630 plus VAT</p>
        </Col>
      </Row>
      <h3 className="text-secondary">One-off Involvement</h3>
      <p>
        Ideally, our work is planned within an annual package of support. However, we recognise that there are times when schools cannot commit to ongoing support and need one-off involvement. Typically, this can be for individual children or young people, where educational psychology involvement is required to understand complex needs and aid 'quick' problem-solving solutions. We, therefore, welcome enquiries for one-off involvement, and we will try and support schools as quickly and effectively as we can.
      </p>
      <p>
        Our costings for one-off involvement do vary from our annual support packages, because, from experience, they do equate to more time and complexity of support to ensure our work is effective. At present, the costings are set at £650 plus VAT per day, and £345 plus VAT per half day.
      </p>
      <p>
        As per packaged based support, we typically attend schools for full days, occasionally for half days, and individual pricing for completed support is discussed and agreed together with school staff. In such discussions, where support is considered to take longer than a day or two, often schools seek the benefit of requesting a three-day annual support package, where the decrease in daily chargeable rate will be applied.
      </p>
      <Row xs={1} md={4} lg={5} className="justify-content-center mt-4">
        <Col className="text-center">
          <HalfDay/>
          <h4 className="text-secondary mt-2">Half Day Session</h4>
          <p className="mb-0">3 Hours</p>
          <p className="mb-4">£345 plus VAT</p>
        </Col>
        <Col className="text-center">
          <FullDay/>
          <h4 className="text-secondary mt-2">Full Day Session</h4>
          <p className="mb-0">6 Hours</p>
          <p className="mb-4">£650 plus VAT</p>
        </Col>
      </Row>
      <h3 className="text-primary">Staff Training</h3>
      <p>
        Staff training is delivered as a twilight, half day or full day session. In all cases, an initial consultation will be completed to explore the purpose and content of the training. A bespoke tailored programme, or a pre-existing training programme will be agreed. After training delivery, a follow up consultation will be made to aid the application of theory into practice.
      </p>
      <p>
        The following prices are inclusive of the completion of all aspects of staff training delivery from the initial consultation, training preparation, delivery and follow-up.
      </p>
      <p>
        Bespoke tailored programmes are costed at the prices above, plus additional planning and preparation time charged at £96.67 per hour.
      </p>
      <Row xs={1} md={4} lg={5} className="justify-content-center mt-4">
        <Col className="text-center">
          <Twilight/>
          <h4 className="text-primary mt-2">Twilight Session</h4>
          <p className="mb-0">1.5 Hours</p>
          <p className="mb-4">£387 plus VAT</p>
        </Col>
        <Col className="text-center">
          <HalfDayTeal/>
          <h4 className="text-primary mt-2">Half Day Session</h4>
          <p className="mb-0">3 Hours</p>
          <p className="mb-4">£677 plus VAT</p>
        </Col>
        <Col className="text-center">
          <FullDayTeal/>
          <h4 className="text-primary mt-2">Full Day Session</h4>
          <p className="mb-0">6 Hours</p>
          <p className="mb-4">£1257 plus VAT</p>
        </Col>
      </Row>
      <h3 className="text-secondary">Consultancy and Project Work</h3>
      <p>
        Our consultancy and project work is offered as a bespoke service, created through collaboration with school staff, to develop a tailored package of support. Each package will be formulated into a number of days work, and charged at £630 plus VAT per day. Any training completed as part of consultancy advice or project work will be charged at the training rate or formulated, separately, as part of the bespoke tailored package.
      </p>
      <Row xs={1} className="justify-content-center mt-4">
        <Col className="text-center">
          <FullDay/>
          <h4 className="text-secondary mt-2">Full Day Session</h4>
          <p className="mb-0">6 Hours</p>
          <p className="mb-4">£630 plus VAT</p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default PackagesPage
